export class UtilCommon {
  /**
   * 給後端的timestamp，需要以「秒」為單位，故除1000。
  */
  static toTimestamp(dateString: string): number {
    const seconds = Math.floor(new Date(dateString).getTime() / 1000);
    return seconds
  }
  /**
   * 傳入日期，取得該日 00:00:00 的timestamp
  */
  static getTimestamp(date: Date): number {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0).getTime();
  }
  
  /**
   * number to byte string, like 5 * 1024 * 1024 = 5MB
   */
  static formatBytes(bytes: number, decimals: number = 0): string {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    const formattedSize = parseFloat((bytes / Math.pow(k, i)).toFixed(dm));

    return `${formattedSize} ${sizes[i]}`;
  }

  /**
   * 計算字數
   */
  static processCharacters(str: string): {
    chinese: number;
    english: number;
    digitsAndSymbols: number;
    other: number;
    str: string;
  } {
    const chineseRegex = /[\u4E00-\u9FA5]/g; // 匹配中文字符的正規表達式
    const englishRegex = /[a-zA-Z]/g; // 匹配英文字符的正規表達式
    const digitSymbolRegex = /[\d\s~`!@#$%^&*()-=_+[\]{}|;:'",.<>/?\\。、，]/g; // 匹配數字、特殊符號、以及中文的正規表達式
    let newStr = '';

    let chineseCount = 0;
    let englishCount = 0;
    let digitSymbolCount = 0;
    let otherCount = 0;

    // 遍历字符串并进行匹配
    for (let char of str) {
      if (char.match(chineseRegex)) {
        chineseCount++;
        newStr += char;
      } else if (char.match(englishRegex)) {
        englishCount++;
        newStr += char;
      } else if (char.match(digitSymbolRegex)) {
        digitSymbolCount++;
        newStr += char;
      } else {
        otherCount++;
      }
    }

    return {
      chinese: chineseCount,
      english: englishCount,
      digitsAndSymbols: digitSymbolCount,
      other: otherCount,
      str: newStr
    };
  }

  /**
   * 取得當前月份縮寫
   */
  static getMonthAbbreviation(date: Date): string {
    // 若有需要i18n，可以改用注入的方式，就是這個陣列是從API來，或者i18n JSON來，等等。
    const monthAbbreviations = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ];
    const monthIndex = date.getMonth();
    return monthAbbreviations[monthIndex];
  }

  /**
   * 計算「日期」這是第幾週
   */
  static getWeekNumber(date: Date): number {
    const startOfYear = new Date(date.getFullYear(), 0, 1);
    const pastDaysOfYear = (date.getTime() - startOfYear.getTime()) / 86400000;
    return Math.ceil((pastDaysOfYear + startOfYear.getDay() + 1) / 7);
  }

  /**
   * javscript Date to Funday date time format
   */
  static dateToFundayDateString(
    date: Date,
    timezone?: number
  ): {
    date: string; // 2024-06-28
    time: string; // 01:59
  } {
    // default is Taiwan time zone +8 hr
    const _timezone = timezone ?? +8;
    date.setDate(_timezone);
    // ISO: '2024-06-28T01:59:27.968Z'
    const dateTime = date.toISOString().split('T');
    const dateString = dateTime[0];
    const timeString = `${date.getHours()}:00`;
    return {
      date: dateString,
      time: timeString
    };
  }

  /**
   * class group 的分類是speaking or writting
   */
  // static getCouseUrlByClassGroupAndClassId(classId: string, classGroup: string): string {
  //     const match = classGroups.some((keyword) => classGroup.includes(keyword));
  //     const type = match ? 'Speaking' : 'Writing';
  //     const cls = classDbMapping[classGroup];
  //     const url = `/lesson?type=${type}&cls=${cls}&classId=${classId}`;
  //     return url
  // }

  /**
   * 課程等級右邊的星星
   */
  static displayStars(level: number): string {
    const fullStar = '★';
    const emptyStar = '☆';
    let stars = '';

    for (let i = 1; i <= 3; i++) {
      stars += i <= level ? fullStar : emptyStar;
    }

    return stars;
  }

  static sleep(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  static getParamsFromUrl(): Record<string, string | string[]> {
    // 1. 獲取 URL 的查詢字符串
    const queryString = window.location.search;

    // 2. 使用 URLSearchParams 解析查詢字符串
    const urlParams = new URLSearchParams(queryString);

    // 3. 將 URLSearchParams 轉換成普通的 JavaScript 物件
    const paramsObject: Record<string, string | string[]> = Object.fromEntries(urlParams.entries());

    // 如果有重複的參數名，例如 `colors`，需要進一步處理它們變成數組
    for (let [key, value] of urlParams.entries()) {
      if (urlParams.getAll(key).length > 1) {
        paramsObject[key] = urlParams.getAll(key);
      }
    }

    return paramsObject;
  }

  static formatDate(date: Date): string {
    // 獲取日期的各個部分
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // getMonth() 從0開始計數
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    // 組合成所需格式
    return `${day}/${month}/${year} ${hours}:${minutes}`;
  }

  static getLocalStorage<T>(key: string): T | null {
    const val = localStorage.getItem(key)
    if (val) {
      return JSON.parse(val) as T;
    }
    return null;
  }

  static setLocalStorage<T>(key: string, val: T): void {
    localStorage.setItem(key, JSON.stringify(val));
  }

  static formatISODateToYYYYMMDD(isoDate: Date): { yyyy: string; mm: string; dd: string } {
    if (!(isoDate instanceof Date)) {
        throw new Error('isoDate must be a Date object');
    }
    const isoString = isoDate.toISOString();
    let datePart = isoString.split('T')[0];
    let [yyyy, mm, dd] = datePart.split('-');
    return { yyyy, mm, dd };
  }

}
