import type { CommonResI } from "@/models/interface/commonResponse"
import axios from "axios"

interface Bulletin {
    data: string
    date: string
    indx: string
}
  

function dto(content: Array<Bulletin>): any {
    const dates: Array<string> = [];
    const data: Array<{
        date: string;
        data: Array<string>;
    }> = [];
    // console.log('useBulletin4', content)
    content.forEach(v => {
        const index = dates.indexOf(v.date);
        if (index == -1) {
            dates.push(v.date);
            data.push({
                date: v.date,
                data: [],
            })
            // console.log('useBulletin5')
        }

        if (data[index]) {
            data[index]?.data?.push(v.data);
            // console.log('useBulletin6')
        }
    })
    console.log('useBulletin7', data)
    return data;
}
/**
 * 
 */
export const useBulletin = () => {
    const state = reactive<{
        isLoading: boolean;
        error: any; // todo: 之後定為數字。
        data: any;
    }>({
        isLoading: true,
        error: undefined,
        data: [],
    })

    async function fetchData() {
        // await UtilCommon.sleep(1000)
        axios.get(`https://funday.asia/api/bulletin.asp`).then((res) => {
            const { errorCode, content }: CommonResI<{
                bulletin: Array<Bulletin>
            }> = res?.data
            // console.log('useBulletin1')
            if (res.status !== 200) {
                state.error = 500
                return
            }
            // console.log('useBulletin2')
            if (errorCode) {
                state.error = errorCode
                return
            }
            console.log('useBulletin3')
            state.data = dto(content.bulletin)
        }).catch(err => {
            state.error = err
        }).finally(() => {
            // state.data = dto({})
            state.isLoading = false
        })
    }

    return {
        state,
        fetchData, 
    };
}
