import type { MemberInfoI } from '@/models/interface/user';
import Http from '@/utils/http';
import axios from 'axios';

const baseUrl: string = import.meta.env.VITE_FUNDAY_BASE_URL;
const socialBaseUrl: string = import.meta.env.VITE_IM_BASE_URL;

export default new (class UserApi extends Http {
    /** 取得會員資訊
     *
     * @returns {Promise<MemberInfo>} User's member data
     */

    /** user login funday token */
    async userLoginFdtk(fdtk: string) {
        const { data } = await axios.get(`${baseUrl}/Auth/ProxyLogin?token=${fdtk}`);
        return data;
    }

    async getMemberInfo(memberId: number | string): Promise<MemberInfoI> {
        const data = await this.GET(`${baseUrl}/Member/GetMemberInfo?member_id=${memberId}`);
        return data;
    }

    /** 修改會員資料 */
    async updateMemberInfo(params: { [key: string]: string | number }): Promise<boolean> {
        const data = await this.POST(`${baseUrl}/Member/UpdateMemberInfo`, params);
        return data;
    }

    /** 變更密碼
     * @param {Object} params 舊密碼與新密碼
     */
    async resetPassword(params: { [key: string]: string | number }): Promise<any> {
        const response = await this.POST(`${baseUrl}/Auth/ResetPassword`, params);

        return response; // 返回解析後的數據
    }

    /** 會員推廣資訊 */
    async userPushInfo(memberId: number | string) {
        const data = await this.GET(`${baseUrl}/MgmPush/pushinfo?member_id=${memberId}`);
        return data;
    }
    async logout(memberId: number): Promise<any> {
        const data = this.POST(`${baseUrl}/Auth/Logout`, {
            memberId
        });
        return data;
    }

    //TODO
    /** 會員來源行銷活動資訊 */
    async marketingAdId() {
        return;
    }

    // 佈告欄
    async getBullet() {
        // const data = this.GET(`https://funday.asia/api/bulletin.asp`);
        const { data } = await axios.get(`https://funday.asia/api/bulletin.asp`);
        return data;
    }

    // social通知
    async getAllNotifications() {
        const data = await this.GET(`${socialBaseUrl}/notifications?notificationType=all`);
        return data;
    }

    // 取得會員該月提早預約次數
    async remainingReservation() {
        const data = await this.GET(`${baseUrl}/Member/remainingreservation`);
        return data;
    }

    async setInitialMemberLevel(params: { [key: string]: number }) {
        const data = await this.POST(`${baseUrl}/Member/InitialMemberLevel`, params);
        return data;
    }
})();
