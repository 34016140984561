import { useUserStore } from '@/stores/user';

interface ApiResponse<T> {
    content: T;
    errorMessage: string | null;
    isSuccess: boolean;
}
const useFetch = async <T>(url: string, options?: any, headers?: any, signal?: AbortSignal) => {
    const userStore = useUserStore();

    if (useExpireToken(userStore.token)) {
        userStore.initUserData();
        window.location.href = '/login';
        return;
    }
    try {
        const reqUrl = url;
        const customHeaders = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userStore.token}`,
            ...headers
        };

        if (options && options.body) {
            options.body = JSON.stringify(options.body);
        }
        const response = await fetch(reqUrl, { ...options, headers: customHeaders, signal });
        if (!response.ok) {
            if (response.status === 401) {
                alert(userStore.forceLogoutMsg);
                userStore.initUserData();
                window.location.href = '/login';
                return;
            }
            const errorData = {
                ...(await response.json()),
                status: response.status
            };
            return errorData;
        }
        // 檢查響應是否有內容
        if (url.indexOf('AddLogs') === -1 && response.ok) {
            const data: ApiResponse<T> = await response.json();
            const result = data.content;
            // console.log("data", data);
            if (url.indexOf('CheckWritingQuota') === -1) {
                // if (!response.ok) {
                //     throw new Error(`HTTP error! Status: ${response.status}`);
                // }
                const pageLink = window.location.href;
                if (data.isSuccess === false) {
                    let res = {};
                    // 處理token失效以及errorMessage回傳
                    if (pageLink.indexOf('aiTutor') !== -1) {
                        res = {
                            promptsRemaining: (data.content as any).promptsRemaining,
                            reply: (data.content as any).reply,
                            errorMessage: data.errorMessage
                        };
                    } else {
                        if (url.indexOf('EarlyReservePlacement') !== -1) {
                            res = {
                                isSuccess: false,
                                errorMessage: data.errorMessage
                            };
                        } else {
                            res = false;
                        }
                    }
                    return res;
                }
                //EarlyReservePlacement return errorMsg
                if(url.indexOf('EarlyReservePlacement') !== -1) return data;
            } else {
                if (data.isSuccess === false) {
                    const errorData = {
                        isSuccess: false,
                        content: data.errorMessage
                    };
                    return errorData; // quota errorMessage
                }
            }
            return result; // 这里直接返回data或者其他的
        }
    } catch (err) {
        return Promise.reject(err);
    }
};

export default class Http {
    GET<T>(url: string, params?: any, headers?: any, signal?: AbortSignal) {
        return useFetch<T>(url, { method: 'GET', params }, headers, signal);
    }

    POST<T>(url: string, body?: any, headers?: any, signal?: AbortSignal) {
        return useFetch<T>(url, { method: 'POST', body }, headers, signal);
    }

    PUT<T>(url: string, body?: any, headers?: any, signal?: AbortSignal) {
        return useFetch<T>(url, { method: 'PUT', body }, headers, signal);
    }

    DELETE<T>(url: string, body?: any, headers?: any, signal?: AbortSignal) {
        return useFetch<T>(url, { method: 'DELETE', body }, headers, signal);
    }
}
