import type { RouteLocationNormalized, NavigationGuardNext, Router } from 'vue-router';
import { useUserStore } from '@/stores/user';
import { jwtDecode } from 'jwt-decode';
import { usePopStore } from '@/stores/pop';
import { useAppStore } from '@/stores/app';
import userApi from '@/services/userApi';
import { UtilCommon } from '@/utils/utilCommot';
import type { StorageSexValue, StorageUserStore } from '@/models/interface/storageValue';
interface jwtDecodeType {
  exp: number;
  iss: string;
  member_id: string;
  nbf: string;
}

function customEncodeURIComponent(str: string) {
  // 使用標準的encodeURIComponent函數進行編碼
  return (
    encodeURIComponent(str)
      // 將編碼後的十六進位數字轉換為小寫
      .replace(/%[0-9A-F]{2}/g, (matched) => matched.toLowerCase())
  );
}

export default async function auth({
  to,
  from,
  next,
  router
}: {
  to: RouteLocationNormalized;
  from: RouteLocationNormalized;
  next: NavigationGuardNext;
  router: Router;
}) {
  const user = useUserStore();
  const appStore = useAppStore();
  if (to.path === '/login') {
    await useCheckAdIdParam();
    if (user.token === '' || user.token === null || user.token === undefined) {
      initUserLoginStatus();
      return next();
    } else {
      if (user.needGetMemberInfo === true) {
        const memberId = jwtDecode<jwtDecodeType>(user.token).member_id;
        usePopStore().loadingStatus = true;
        user.userData = await userApi.getMemberInfo(memberId);
        user.isLogin = true;
        user.needGetMemberInfo = false;
        const waitingTime = (): Promise<boolean> => {
          return new Promise((resolve) => {
            setTimeout(() => {
              resolve(true);
            }, 500);
          });
        };
        if ((await waitingTime()) === true) {
          usePopStore().loadingStatus = false;
          return next();
        } else {
          usePopStore().loadingStatus = false;
          return next(false);
        }
      }
      // 這段看起來都不會被觸發到，只有新加的footer跳轉回landingPage會到這
      //  else {
      //   router.push('/');
      //   window.location.pathname = '/';
        return next();
      // }
    }
  } else {
    // 之後需要完全移除fdtk邏輯
    if (to?.query?.fdtk) {
      // 驗證fdtk是否有效
      const fdtk = customEncodeURIComponent(to.query.fdtk as string);
      if (fdtk) {
        const res = await userApi.userLoginFdtk(fdtk);
        if (res.content) {
          const data = res.content;
          const memberId = jwtDecode<jwtDecodeType>(data).member_id;
          user.token = data;
          usePopStore().loadingStatus = true;
          user.userData = await userApi.getMemberInfo(memberId);
          console.log('user.userData', user.userData);
          user.isLogin = true;
          user.needGetMemberInfo = false;

          const waitingTime = (): Promise<boolean> => {
            return new Promise((resolve) => {
              setTimeout(() => {
                resolve(true);
              }, 500);
            });
          };

          if ((await waitingTime()) === true) {
            usePopStore().loadingStatus = false;
            return next();
          } else {
            usePopStore().loadingStatus = false;
            return next(false);
          }
        }
      }
    } else {
      // 原始邏輯
      // useCheckAdIdParam();
      if (user.isLogin === true) {
        // 檢查token是否過期
        if (user.token === '' || user.token === null || user.token === undefined) {
          initUserLoginStatus();
          window.location.href = '/login';
          return next();
        }

        const expireToken = useExpireToken(user.token);
        if (expireToken) {
          alert(user.forceLogoutMsg);
          initUserLoginStatus();
          window.location.href = '/login';
          return next();
        } else {
          if (user.needGetMemberInfo === true) {
            const memberId = jwtDecode<jwtDecodeType>(user.token).member_id;
            usePopStore().loadingStatus = true;
            user.userData = await userApi.getMemberInfo(memberId);
            user.isLogin = true;
            user.needGetMemberInfo = false;

            const storageUser =
              UtilCommon.getLocalStorage<StorageUserStore>('new-funday-userStore');
            const storage = UtilCommon.getLocalStorage<StorageSexValue[]>('sex');
            const newStorage: StorageSexValue[] = [
              {
                userAccount: storageUser?.userAccount ?? '',
                value: user.userData.sex
              }
            ];
            if (storage) {
              newStorage.push(
                ...storage.filter((item) => item.userAccount !== (storageUser?.userAccount ?? ''))
              );
            }
            UtilCommon.setLocalStorage<StorageSexValue[]>('sex', newStorage);

            const waitingTime = (): Promise<boolean> => {
              return new Promise((resolve) => {
                setTimeout(() => {
                  resolve(true);
                }, 500);
              });
            };

            if ((await waitingTime()) === true) {
              usePopStore().loadingStatus = false;

              return next();
            } else {
              usePopStore().loadingStatus = false;
              return next(false);
            }
          } else {
            console.log(6);
            return next();
          }
        }
      } else {
        usePopStore().loadingStatus = true;
        console.log('Query ad_id');

        // find ad_id value in window.location.search
        // await useCheckAdIdParam();
        window.location.href = '/login';
        usePopStore().loadingStatus = false;
        return next();
      }
    }
  }
}
const initUserLoginStatus = () => {
  const user = useUserStore();
  user.needGetMemberInfo = true;
  user.isLogin = false;
  user.token = '';
  localStorage.removeItem('questionnaireIsShowed');
};
