import type {
    CourseGoal,
    ClassDetail,
    ClassTimeParams,
    ClassroomReserveStatus,
    HightLightReviewParams,
    ReviewDetail
} from '@/models/interface/course';
import { useUserStore } from '@/stores/user';
import axiosInstance from '@/utils/axios';

import Http from '@/utils/http';

const baseUrl: string = import.meta.env.VITE_FUNDAY_BASE_URL;
const classroomBaseUrl: string = import.meta.env.VITE_FUNDAY_REDIS_URL;

export default new (class Course extends Http {
    /**
     * 取得課程進度和時間目標API
     * @param type 目標類型
     * @param memberLevel 會員等級
     * @returns
     */
    async getCourseGoalAndTime(type: number | string, memberLevel: number | string) {
        const data = await this.GET<CourseGoal>(
            `${baseUrl}/Schedule/GetCourseGoalAndTime?type=${type}&memberLevel=${memberLevel}`
        );
        return data as CourseGoal;
    }

    /**
     * 取得當時段符合條件的課程
     * @param params
     * @returns
     */
    async getCoursesByTime(params: ClassTimeParams) {
        const { startDate, endDate, startTime, endTime, classGroup, classLevel } = params;

        // todo: 短解決
        const ClassLevel: Array<number> | undefined = (classLevel == undefined || (classLevel + '') == '-1') ? undefined : classLevel.map(v => Number.parseInt(v + ''));
        console.log("classGroup - 1", classGroup[0])
        const res = await axiosInstance.get(`/Schedule/GetClassesByTime`, {
            params: {
                StartDate: startDate,
                EndDate: endDate,
                StartTime: startTime,
                EndTime: endTime,
                ClassGroup: classGroup,
                ClassLevel,
            }
        })
        const data = res.data?.content;
        return data as ClassDetail;
    }

    /**
     * 取得該課程的候位狀態
     * @param classId 課程ID
     */
    async getCourseReserveStatus(classId: string) {
        try {
            const response = await fetch(
                `${baseUrl}/TutorClass/GetLimitForRoom?LimitForRoom=${classId}:Reserve`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${useUserStore().token}`
                    }
                }
            );

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data: ClassroomReserveStatus = await response.json();

            return data;
        } catch (error) {
            console.error('Error fetching pronunciation data:', error);
            throw error;
        }
    }

    /** 取得精選回顧影片(名師講堂 & 國高中課程) */
    async getHightLightReview(params: HightLightReviewParams): Promise<ReviewDetail[]> {
        const { classGroup, pg } = params;

        const pgSize = 20;
        const data = await this.GET(
            `${baseUrl}/Schedule/GetHighlightReview?ClassGroup=${classGroup}&Pg=${pg}&PgSize=${pgSize}`
        );
        return data as Promise<ReviewDetail[]>;
    }

    /** 確認下載扣打 */
    // async checkDownloadQuota(memberId: number, classId: number) {
    async checkDownloadQuota(classId: number) {
        try {
            const response = await fetch(
                `${baseUrl}/Schedule/CheckDownloadQuota?class_id=${classId}`,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${useUserStore().token}`
                    }
                }
            );

            if (!response.ok) {
                const data = await response.json();
                // console.log(data)
            } else {
                const data = await response.json();
                // console.log(data)
                return data;
            }
        } catch (error) {
            console.error('Error fetching pronunciation data:', error);
            throw error;
        }
    }

    /** 播放下載課程影片 */
    async playVideo(params: { [key: string]: string | number }): Promise<boolean> {
        const { memberId, classId, classGroup } = params;

        const data = await this.POST(
            `${baseUrl}/Schedule/PlayVideo?member_id=${memberId}&classId=${classId}&classGroup=${classGroup}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${useUserStore().token}`
                }
            }
        );

        return data as Promise<boolean>;
    }

    /** 取得當下即將開課的免費課程內容
     *
     * @returns
     */
    async getFreeClass<T>(): Promise<T> {
        const data = await this.GET<T>(`${baseUrl}/Schedule/FreeClass`);
        return data as Promise<T>;
    }
})();
