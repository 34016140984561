<script lang="ts" setup>
import { useUserStore } from '@/stores/user';
import { useAppStore } from '@/stores/app';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css';
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue';

const props = defineProps({
  isSidebar: Boolean
});

const appStore = useAppStore();
const userStore = useUserStore();
const modules = [Autoplay, Pagination];
const isMobileEvent = ref(false);

const campusLink = computed(() => {
  return `https://campus.funday.asia/social?token=${useUserStore().token}`;
});

const newMemberEvent = computed(() => {
  return {
    image_desktop: 'M.jpg',
    image_pad: 'M.jpg',
    image_mobile: 'M-mobile.jpg',
    href: '/schedule'
  };
});

const roleEvents = computed(() => {
  switch (userStore.adRole) {
    case 'M': // 推薦人未購
      return {
        image_desktop: 'M.jpg',
        image_pad: 'M.jpg',
        image_mobile: 'M-mobile.jpg',
        href: 'https://funday.asia/subscription/2023/'
      };
      break;

    case 'V': // 試用會員:註冊第一天
      return {
        image_desktop: 'V.jpg',
        image_pad: 'V.jpg',
        image_mobile: 'V-mobile.jpg',
        href: 'https://funday.asia/subscription/2023/'
      };
      break;

    case 'P': // 試用會員:試用期內
      return {
        image_desktop: 'P.jpg',
        image_pad: 'P.jpg',
        image_mobile: 'P-mobile.jpg',
        href: 'https://funday.asia/subscription/2023/'
      };
      break;

    case 'Y': // 試用會員:試用期結束
      return {
        image_desktop: 'Y.jpg',
        image_pad: 'Y.jpg',
        image_mobile: 'Y-mobile.jpg',
        href: 'https://funday.asia/subscription/2023/'
      };
      break;

    case 'O': // 已購會員:小單499
      return {
        image_desktop: 'M.jpg',
        image_pad: 'M.jpg',
        image_mobile: 'M-mobile.jpg',
        href: 'https://funday.asia/subscription/2023/'
      };
      break;

    case 'Bu': // 已購會員:VIP
      return {
        image_desktop: 'M.jpg',
        image_pad: 'M.jpg',
        image_mobile: 'M-mobile.jpg',
        href: 'https://funday.asia/subscription/2023/'
      };
      break;

    case 'R': // 已購會員:VIP過期前30天
      return {
        image_desktop: 'R.jpg',
        image_pad: 'R.jpg',
        image_mobile: 'R-mobile.jpg',
        href: 'https://funday.asia/subscription/2023/'
      };
      break;
    case 'Ba': // 已購會員:VIP過期
      return {
        image_desktop: 'Ba.jpg',
        image_pad: 'Ba.jpg',
        image_mobile: 'Ba-mobile.jpg',
        href: 'https://funday.asia/subscription/2023/'
      };
      break;

    default:
      break;
  }
});

const events: { [key: string]: string }[] = [
  {
    // 中班教室
    image_desktop: 'middle-classroom.jpg',
    image_pad: 'middle-classroom.jpg',
    image_mobile: 'middle-classroom-mobile.jpg',
    href: 'https://funday.asia/',
    target: '_self'
  },
  {
    // 新/介面聽力節目
    image_desktop: 'listening-desktop.jpg',
    image_pad: 'listening-desktop.jpg',
    image_mobile: 'listening-mobile.jpg',
    href: 'https://funday.asia/',
    target: '_self'
  }
];

const eventsMobile = computed(() => {
  if (isMobileEvent.value) {
    return events.filter((e) => e.image_mobile !== 'Firm-mobile.jpg');
  }
  return events;
});

const isOpenCard = defineModel('isOpenCard');

const logoFileName = computed(() => {
  return appStore.isMobile ? 'FUNDAY-lg' : 'FUNDAYLOGO';
});

const handleResize = () => {
  if (window.innerWidth <= 1025) {
    isMobileEvent.value = true;
  } else {
    isMobileEvent.value = false;
  }
};

const handleClick = () => {
  appStore.isShowEvent = false;
};

const handleCloseEvent = () => {
  if (!userStore.isLogin || !userStore.token.length) return;
  isOpenCard.value = false;
};
watch(
  () => isOpenCard.value,
  (val) => {
    if (val) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }
);

onMounted(() => {
  handleResize();
  window.addEventListener('resize', handleResize);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize);
});
</script>
<template>
  <div v-if="isOpenCard" class="event-card">
    <div v-if="!props.isSidebar" class="logo">
      <img
        loading="lazy"
        fetchpriority="high"
        decoding="async"
        :src="`/images/logo/${logoFileName}.svg`"
        alt="FUNDAY"
      />
    </div>
    <div v-if="!props.isSidebar" class="close" @click="handleCloseEvent" />
    <swiper
      class="fullPage"
      :spaceBetween="30"
      :autoplay="{
        delay: 3500,
        disableOnInteraction: false
      }"
      :pagination="{
        clickable: true
      }"
      :modules="modules"
    >
      <swiper-slide v-if="userStore.isAfterSignUp">
        <a href="/schedule" target="_blank" class="link" @click="handleClick">
          <img
            v-if="!isMobileEvent"
            :src="`/images/event/${roleEvents?.image_desktop}`"
            alt=""
            class=""
          />
          <img v-else :src="`/images/event/${roleEvents?.image_mobile}`" alt="" class="" />
        </a>
      </swiper-slide>

      <!-- For Specific Role -->
      <!-- <swiper-slide>
                <a :href="roleEvents?.href" target="_blank" class="link" @click="handleClick">
                    <img
                        v-if="!isMobileEvent"
                        :src="`/images/event/${roleEvents?.image_desktop}`"
                        alt=""
                        class=""
                    />
                    <img
                        v-else
                        :src="`/images/event/${roleEvents?.image_mobile}`"
                        alt=""
                        class=""
                    />
                </a>
            </swiper-slide> -->

      <!-- For Everyone -->
      <swiper-slide v-for="event in eventsMobile">
        <a :href="event.href" :target="event.target" class="link" @click="handleClick">
          <img
            v-if="!isMobileEvent"
            :src="`/images/event/${event.image_desktop}`"
            alt=""
            class=""
          />
          <img v-else :src="`/images/event/${event.image_mobile}`" alt="" class="" />
        </a>
      </swiper-slide>
    </swiper>
  </div>
</template>
<style lang="scss" scoped>
.event-card {
  @include fixed($T: 0, $L: 0);
  width: 100%;
  // height: 100dvh;
  height: 100%;
  z-index: 10000;
}

.logo {
  @include absolute($T: 20px, $L: 20px);
  display: block;
  margin-inline: 0 auto;
  height: 100px;
  max-width: 206px;
  // width: 10%;
  z-index: 10000;
}

.swiper {
  &.fullPage {
    width: 100%;
    height: 100%;
    background: $color-white-base;
  }

  &-slide {
    img {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      /* 或其他寬度值，以填滿容器 */
      height: 100%;
      /* 或其他高度值，以填滿容器 */
      object-fit: cover;
      /* 控制圖片如何填滿元素 */

      @media (max-width: 1024px) {
        position: static;
      }
    }
  }
}

.close {
  @include absolute($T: 20px, $R: 20px);
  @include wh(40);
  z-index: 10000;
  cursor: pointer;
  background: $color-black-05;
  border-radius: 50%;

  &:before {
    @include absolute($T: 50%, $L: 50%);
    content: '';
    width: 20px;
    height: 1px;
    border-radius: 1px;
    background: $color-white-80;
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &:after {
    @include absolute($T: 50%, $L: 50%);
    content: '';
    width: 20px;
    height: 1px;
    border-radius: 1px;
    background: $color-white-80;
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}
</style>
